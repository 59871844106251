<template>
  <div class="animated">
    <b-card no-body>
      <b-card-header>
        <i class="fa fa-filter mr-1"></i>
        {{ $t("filter") }}
        <div class="card-header-actions">
          <BrandButton
            :text="$t('newTag')"
            @click="add()"
            v-if="$auth.hasAuth($options, 'add')"
          ></BrandButton>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label for="activeState">{{ $t("active") }}</label>
              <ActiveStateDropDown
                :activeState="filterObject.active"
                @change="activeStateChanged"
              ></ActiveStateDropDown>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TagTableComponent
      :filters="filterObject"
      v-if="$auth.hasAuth($options, 'list')"
    ></TagTableComponent>
  </div>
</template>

<script>
import TagTableComponent from "../../widgets/tag/TagTable";
import ActiveStateDropDown from "../../widgets/ActiveStateDropDown";
import BrandButton from "../../widgets/form/CustomBrandButton";
export default {
  name: "TagList",
  components: {
    TagTableComponent,
    ActiveStateDropDown,
    BrandButton,
  },
  methods: {},
  data() {
    return {
      filterObject: {
        active: null,
      },
    };
  },
  methods: {
    activeStateChanged(activeState) {
      this.filterObject.active = activeState;
    },
    add() {
      this.$router.push({ path: "tag-add" });
    },
  },
};
</script>


